<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    stroke="#3a405a"

    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.56543 20.8335L11.0655 11.3335M11.0655 11.3335L20.5654 1.8335M11.0655 11.3335L1.56543 1.8335M11.0655 11.3335L20.5654 20.8335"
      stroke-width="2"
      class="styleIcon"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

